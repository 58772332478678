export const custom = {
  /**
  * Idiomas disponibles ['es','en','pt','cat']
  */
  availableLangs: ['es', 'en', 'cat'],

  /**
   * Idioma por defecto: ['es']
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: '8901ce35-8cb3-4959-b25f-7df2b212c486',

  /**
   * Extensión en producción de URL de la app
   * (es, com, org...)
   */
   appUrlTld: 'com',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'vivofacil360.vivofacil',

  /**
  * Nombre de la app
  */
  appName: 'Vivofácil 360º',

  /**
   * Nombre de marca interno (Alares, Vivofácil...)
   */
   brandName: 'Vivofácil',

  /**
   * Mail transport (alares, vivofacil...)
   */
   mailTransport: 'vivofacil',

  /**
  * Código de analytics 'G-XXXXXXXXXXX'
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-8BSDYW8FVP',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: true,

  /** Registro habilitado o deshabilitado */
  registration: false,

  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: true,

  /** testamentis */
  testamentisHereditary: 'https://formularios.alares.local/form/create/1edaeaf3-6bc3-651c-ae2c-1b9a90d4d278/embed',
  testamentisLiving: 'https://formularios.alares.local/form/create/1ed08dea-bf79-6442-8808-436f6414e40d/embed'
};
